#regen {
  position: fixed;
  top: 0;
}

#restart {
  position: fixed;
  top: 30px;
}

/*# sourceMappingURL=index.5b0a11c5.css.map */
